/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.content-info .nav-social li a').addClass('ss-icon');

//         $("[data-rel^='prettyPhoto']").fancybox().click();

        $('.navbar-main .menu-request-boves-locally a').tooltip({
          placement:'bottom'
        });

        $('a').each(function() {
          var a = new RegExp('/' + window.location.host + '/');
          if (!a.test(this.href)) {
          	$(this).attr("rel","external").attr("target","_blank");
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


        $(document).scroll(function() {
          var y = $(this).scrollTop();
          var t = $('#hometitle').parent().offset().top;
          t=t+200;
          if (y > t) {
            $('header.banner.navbar-default').addClass('homedisplay');
          } else {
            $('header.banner.navbar-default').removeClass('homedisplay');
          }
        });

        $('.tripfade').slick({
          dots: false,
          infinite: true,
          speed: 800,
          autoplaySpeed: 5000,
          fade: true,
          arrows: false,
          autoplay: true,
          cssEase: 'linear'
        });

        $('.triptych .item').hover(
          function() {
            $('.triptych .item').removeClass('shown');
            $(this).addClass('shown');
          }, function() {
            $('.triptych .item').removeClass('shown');
          }
        );
/*
        alert($('.triptych #items-0 .item:first-child .attachment-triptych').height());
        $('.triptych #items-0 .item:first-child .attachment-triptych').load(function() {
          var iheight=$(this).height();
          alert(iheight);
          $('.triptych #items-1 .item .attachment-triptych').height(iheight);
        });
*/


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_recipe': {
      init: function() {
        $('.menu-item.menu-recipes').addClass('active');
      }
    },
    'tax_product_cat': {
      init: function() {
        $('.nav-products .menu-all-products').removeClass('active');
      }
    },
    'woocommerce': {
      init: function() {
        $('.products .product').matchHeight();
      }
    },
    'cart': {
      init: function() {
        $('.menu-item.menu-shop').addClass('active');
      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
